.Alert {
    display: flex;
    flex-direction: row;
    color: white;
    opacity: 0.85;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.Content {
    flex: 1;

    margin: 1em;
    font-weight: 700;
    text-align: center;
    white-space: pre-wrap;

    & a[href],
    & li::marker {
        color: inherit;
    }
}

.FlexContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.OneLiner {
    &,
    & * {
        margin: 0;
        display: inline;
    }
}

.Close {
    padding: 0 1em;
    color: inherit;
}

a[href] {
    & .Content {
        &:after {
            content: '\A0\203A';
        }
    }
}

.Danger {
    composes: Alert;

    background-color: var(--danger);
}

.Info {
    composes: Alert;

    background-color: var(--info);
    & a {
        text-decoration: underline;
    }
}

.Warning {
    composes: Alert;

    background-color: var(--warning);
    color: var(--black);

    & a[href] {
        color: var(--primary);
        text-decoration: underline;
    }

    & .Content a[href]:only-child {
        color: inherit;
    }
}

.Success {
    composes: Alert;

    background-color: var(--success);
}
